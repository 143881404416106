<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <!-- <div class="app-logo">
      <router-link to="/">
        <img src="@/assets/metabetall.png">
      </router-link>
    </div> -->
    <MemberProfile />
    <!-- <RoundPayrate /> -->
  </CSidebar>
</template>

<script>
import MemberProfile from './MemberProfile'
// import RoundPayrate from '@/views/PlayLotto/components/RoundPayrate'

export default {
  name: 'TheSidebar',
  components: {
    MemberProfile,
    // RoundPayrate
  },
  //nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
<style lang="scss">
.app-logo {
  display: flex;

  a {
    text-align: center;
    img {
      max-width: 90%;
      margin: 10px auto 0;
    }
  }
}
</style>
