<template>
  <CHeader fixed with-subheader light>

    <CHeaderNav class="mr-auto container-fluid d-flex">
      <CHeaderNavItem class="px-0">
        <CHeaderNavLink :to="{name: 'Home'}" class="px-3" active-class="font-weight-bold nav-active">
          <i class="fas fa-home mr-1"></i> หน้าหลัก
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-0">
        <CHeaderNavLink :to="{name: 'PlayLotto'}" class="px-3" active-class="font-weight-bold nav-active">
          <i class="fas fa-pen mr-1"></i> ทายผล
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-0">
        <CHeaderNavLink :to="{name: 'Tickets'}" class="px-3" active-class="font-weight-bold nav-active">
          <i class="fas fa-list mr-1"></i> รายการโพย
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-0">
        <CHeaderNavLink :to="{name: 'Report'}" class="px-3" active-class="font-weight-bold nav-active">
          <i class="fas fa-search-dollar mr-1"></i> การเงิน
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-0">
        <CHeaderNavLink :to="{name: 'Results'}" class="px-3" active-class="font-weight-bold nav-active">
          <i class="fas fa-star mr-1"></i> ตรวจผล
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- <CHeaderNavItem class="px-0">
        <CHeaderNavLink :to="{name: 'Help'}" class="px-3" active-class="font-weight-bold nav-active">
          <i class="fas fa-book mr-1"></i> คู่มือการใช้งาน
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <CHeaderNavItem class="px-0">
        <CHeaderNavLink :to="{name: 'Links'}" class="px-3" active-class="font-weight-bold nav-active">
          <i class="fas fa-link mr-1"></i> ลิงค์ดูผล
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-0">
        <a href="#" @click="logout" class="c-header-nav-link px-3"><i class="fas fa-sign-out-alt mr-1"></i> ออกจากระบบ </a>
      </CHeaderNavItem>
    </CHeaderNav>

  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  methods: {
    logout() {
      if(this.$store.state.isProcessTicket) {
        Swal.fire({
          text: 'กรุณาอย่าเปลี่ยนหน้าขณะกำลังส่งโพย',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }else{
        Auth.logout()
      }
    }
  }
}
</script>
